import Strings from "../strings/Strings";
import {refreshToken} from "./ServerPetitions";

// Función que permite comprobar si existen errores en la variable pasada. Usado en formularios.
export const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));
    return valid;
};

// Función que establece el año de copyright en el footer.
export const setCopyright = () => {
    let strings = new Strings();
    let copyright = document.getElementById("footer-copyright");
    if (copyright) {
        copyright.innerHTML = strings.copyright;
    }
};

// Carga la imagen y el loginname del usuario.
export const loadSysAdminImageAndName = (aliveSysAdmin) => {
    let strings = new Strings();
    let sysadmin_img = document.getElementById("sysadmin_image");
    if (sysadmin_img) {
        sysadmin_img.src = strings.API_URL + "images/user/" + aliveSysAdmin.id + ".jpg";
        sysadmin_img.onerror = function () {
            this.src = "/img/ico_person.png";
        };
        document.getElementById("sysadmin_loginname").innerHTML = aliveSysAdmin.loginname;
    }
};

// Transforma una fecha pasada como cadena con 2 valores en campos de día y mes a fecha con formato año-mes-día.
export const jsonStringToDate = (string) => {
    const pattern = /(\d{2})-(\d{2})-(\d{4})/;
    return new Date(string.replace(pattern,'$3-$2-$1'));
};

// Transforma una fecha pasada como cadena con 1 o 2 valores en campos de día y mes a fecha con formato año-mes-día.
export const stringToDate = (string) => {
    const pattern = /(\d{1,2})-(\d{1,2})-(\d{4})/;
    return new Date(string.replace(pattern,'$3-$2-$1'));
};

// Transforma un datetime a cadena.
export const datetimeToString = (date) => {
    return String(date.getDate()).padStart(2, '0')  + "-" + String(date.getMonth()+1).padStart(2, '0') + "-" +
        date.getFullYear() + " " + String(date.getHours()).padStart(2, '0') + ":" +
        String(date.getMinutes()).padStart(2, '0') + ":" + String(date.getSeconds()).padStart(2, '0');
};

// Función que ocuta el símbolo de cargar.
export const endPreLoad = () => {
    document.getElementById("preload").classList.add("d-none");
};

// Pide el access_token si es necesario.
export const refreshIfNeeded = (jsonResponse, hideLoaderModal, showInfoModal) => {
    const strings = new Strings();
    if (jsonResponse.hasOwnProperty('error') && jsonResponse.error[0].hasOwnProperty('idsubcat')){
        if (jsonResponse.error[0]['idsubcat'] === "303") {
            window.location.href = '/';
        } else if (jsonResponse.error[0]['idsubcat'] === "305"){
            refreshToken().then(async response => {
                const responseJson = await response.json();

                if (!response.ok){
                    const error = response.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (responseJson.hasOwnProperty('access_token')){
                    saveAccessToken(responseJson);
                    window.location.reload();
                } else {
                    window.location.href = '/';
                }
            });
        } else {
            if (hideLoaderModal !== null) {
                hideLoaderModal();
            }
            if (showInfoModal !== null) {
                showInfoModal(strings.updateModalErrorTitle, strings.updateModalErrorBody);
            }
        }
    }
}

// Guarda el access_token con el resto de información.
export const saveAccessToken = (access_token) => {
    let strings = new Strings();
    localStorage.setItem(strings.credentialsKey, JSON.stringify(access_token));
}

// Se usa para convertir una imagen a web.
export const toCustomDataURL = (url, callback) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = function() {
        let reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

// Se usa para convertir una url a base64.
export const getBase64Image = (img) => {
    img.setAttribute('crossOrigin', 'anonymous');
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    let dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}