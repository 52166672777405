import InfoMessage from "./InfoMessage";
import NotificationStatus from "./NotificationStatus";
import NotificationReceiver from "./NotificationReceiver";

// Clase del objeto OutMessage.
class OutMessage {
    // Constructor del objeto OutMessage.
    constructor(props) {
        this.type = "OutMessage";
        if (props !== null){
            this.info = new InfoMessage(props.info);
            this.status = new NotificationStatus(props.status);
            this.receiver = [];
            for (let i in props.receiver){
                this.receiver.push(new NotificationReceiver(props.receiver[i]));
            }
        } else {
            this.info = null;
            this.status = null;
            this.receiver = [];
        }
    }
}

export default OutMessage;