import React, {Component} from 'react';
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import {getJobAsync, getJobAsyncResult, getUserList} from "../../../logic/functions/ServerPetitions";
import User from "../../../logic/objects/User";
import PageTitle from "../../component/PageTitle";
import ReactAsyncTable from 'react-async-table';
import JobAsync from "../../../logic/objects/JobAsync";

// Clase que contiene la información de la página de información de usuarios.
class UserInfo extends Component {
    // Constructor de clase UserInfo.
    constructor(props) {
        super(props);
        this.header = [{
            text: this.props.strings.tableId,
            dataField: 'id'
        }, {
            text: this.props.strings.tableUsername,
            dataField: 'username'
        }, {
            text: this.props.strings.tableEmail,
            dataField: 'email'
        }, {
            text: this.props.strings.tableName,
            dataField: 'name'
        }, {
            text: this.props.strings.tableSurname,
            dataField: 'surname'
        }, {
            text: this.props.strings.tableNSeason,
            dataField: 'nseason'
        },{
            text: this.props.strings.tablePhone,
            dataField: 'phone'
        },{
            text: this.props.strings.tableNIF,
            dataField: 'nif'
        }, {
            text: this.props.strings.userTablePremiumCoop,
            dataField: 'premiuma'
        }, {
            text: this.props.strings.userTablePremiumOffline,
            dataField: 'premiumb'
        }, {
            text: this.props.strings.userTablePremiumPhyto,
            dataField: 'premiumc'
        }, {
            text: this.props.strings.userTablePremiumAnalysis,
            dataField: 'premiumd'
        },{
            text: this.props.strings.userTablePremiumStock,
            dataField: 'premiume'
        },{
            text: this.props.strings.userTablePremiumGaia,
            dataField: 'premiumf'
        },{
            text: this.props.strings.userTablePremiumNirs,
            dataField: 'premiumg'
        },{
            text: this.props.strings.tableOrganization,
            dataField: 'org'
        }];
        this.state = {tablebody: [], petitionended: false, maxamount: 0, page_size: 20, page: 0,
            isLoading: true, query: "", exitPetition: false};
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.userTitle;
        getUserList(this.state.page_size, this.state.page, this.state.query).then(async userList => {
            const usersJson = await userList.json();
            this.setState({maxamount: parseInt(userList.headers.get("content-range").split(" ")[2])});

            if (!userList.ok){
                const error = userList.status;
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (usersJson.hasOwnProperty('data')){
                let jobAsync = new JobAsync(usersJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncGetUsersMessage(jobAsync.id, this.props.globals.getMinTimeout());
            } else if (usersJson.hasOwnProperty('error') && usersJson.error[0].hasOwnProperty('idsubcat') && usersJson.error[0]['idsubcat'] === "303"){
                window.location.href = '/';
            }
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            endPreLoad();
        });
    }

    // Función que se realiza al abandonar la página. Cancela las peticiones async.
    componentWillUnmount = () => {
        this.setState({exitPetition: true});
    }

    // Dibuja la página de información de usuarios.
    render = () => {
        let table = '';
        if (this.state.petitionended){
            table = <ReactAsyncTable keyField={"id"} columns={this.header} items={this.state.tablebody} isLoading={this.state.isLoading}
                         currentPage={this.state.page+1} itemsPerPage={this.state.page_size} totalItems={this.state.maxamount}
                         onChangePage={this.onChangePage} onSearch={this.onSearch} loader={this.tableLoader} delay={this.props.globals.getAsyncDelay()}
                         translations={{
                             searchPlaceholder: this.props.strings.tableSearch,
                             noDataText: this.props.strings.userTableNotFound,
                             requestFailedText: this.props.strings.tableSearchError,
                             paginationFirst: this.props.strings.tablePaginationFirst,
                             paginationLast: this.props.strings.tablePaginationLast
                         }}/>;
        }

        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.userInfoPageTitle}/>

                <div className="card shadow mb-4">
                    <div id="userListTable" className="card-body">
                        {table}
                    </div>
                </div>
            </div>
        );
    }

    // Rellena la lista de usuarios de la tabla.
    fillUserListTable = (users) => {
        for (let i in users){
            let newTableBody = this.state.tablebody;
            newTableBody.push({id: users[i].getId(), username: users[i].getLoginname(), email: users[i].getEmail(),
                name: users[i].getName(), surname: users[i].getSurname(), nseason: users[i].getNf(), company: users[i].getCompany(),
                phone: users[i].getPhone(), nif: users[i].getNif(), premiuma: (users[i].isPremiumA() ? this.props.strings.yes : this.props.strings.no),
                premiumb: (users[i].isPremiumB() ? this.props.strings.yes : this.props.strings.no), premiumc: (users[i].isPremiumC() ? this.props.strings.yes : this.props.strings.no),
                premiumd: (users[i].isPremiumD() ? this.props.strings.yes : this.props.strings.no), premiume: (users[i].isPremiumE() ? this.props.strings.yes : this.props.strings.no),
                premiumf: (users[i].isPremiumF() ? this.props.strings.yes : this.props.strings.no), premiumg: (users[i].isPremiumG() ? this.props.strings.yes : this.props.strings.no),
                org: (users[i].getKey() !== null ? (users[i].getKey().getOrganization() !== null ? users[i].getKey().getOrganization().getName() : "") : "")});
            this.setState({tablebody: newTableBody});
        }
        this.setState({petitionended: true});
    }

    // Muestra el mensaje de cargando usuarios cuando se cambia de página en la tabla.
    tableLoader = () => {
        return <div>
            <h5 className="modal-title mb-3 text-center">{this.props.strings.userLoadingUsers}</h5>
            <div className="row justify-content-center">
                <div className="spinner-border text-success" role="status"><span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>;
    }

    // Realiza el cambio de página de la tabla.
    onChangePage = (page) => {
        this.setState({tablebody: [], isLoading: true, page: page-1}, () => {
            this.getUserListPage();
        });
    }

    // Realiza el filtrado de la tabla.
    onSearch = (query) => {
        this.setState({tablebody: [], isLoading: true, page: 0, query: query}, () => {
            this.getUserListPage();
        });
    }

    // Realiza la petición de la página de usuarios pedida en la tabla.
    getUserListPage = () => {
        getUserList(this.state.page_size, this.state.page, this.state.query).then(async userList => {
            const usersJson = await userList.json();
            this.setState({maxamount: parseInt(userList.headers.get("content-range").split(" ")[2])});

            if (!userList.ok){
                const error = userList.status;
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (usersJson.hasOwnProperty('data')){
                let jobAsync = new JobAsync(usersJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncGetUsersMessage(jobAsync.id, this.props.globals.getMinTimeout());
            } else if (usersJson.hasOwnProperty('error') && usersJson.error[0].hasOwnProperty('idsubcat') && usersJson.error[0]['idsubcat'] === "303"){
                window.location.href = '/';
            }
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
        });
    }

    // Petición asíncrona para obtener lista de usuarios de oSIGris.
    getJobAsyncGetUsersMessage = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async usersPromise => {
                const usersPromiseJson = await usersPromise.json();

                if (!usersPromise.ok){
                    const error = usersPromise.status;
                    return Promise.reject(error);
                }

                if (usersPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(usersPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data') && jobAnswerJson.data.length > 0){
                                this.setState({tablebody: []}, () => {
                                    let users = [];
                                    for (let i in jobAnswerJson.data) {
                                        users.push(new User(jobAnswerJson.data[i]));
                                    }
                                    this.fillUserListTable(users);
                                    this.setState({isLoading: false});
                                });
                            } else if (jobAnswerJson.hasOwnProperty('error') && jobAnswerJson.error[0] && jobAnswerJson.error[0].hasOwnProperty('idcat') && jobAnswerJson.error[0].idcat === '404'){
                                this.setState({tablebody: [], isLoading: false});
                            } else {
                                refreshIfNeeded(jobAnswerJson, null, this.props.showInfoModal);
                            }
                            endPreLoad();
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            this.setState({isLoading: false});
                            console.log(error);
                        });
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncGetUsersMessage(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(usersPromiseJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                this.setState({isLoading: false});
                console.log(error);
            });
        }, timeout);
    }
}

export default UserInfo;