import Company from "./Company";
import Substance from "./Substance";
import TypePhytosanitaryCropInfection from "./TypePhytosanitaryCropInfection";

// Clase del objeto TypePhytosanitary.
class TypePhytosanitary {
    // Constructor del objeto TypePhytosanitary.
    constructor(props) {
        this.type = "TypePhytosanitary";
        if (props !== null) {
            this.id = props.id;
            this.idpdf = props.idpdf;
            this.code = props.code;
            this.name = props.name;
            this.names = [];
            for (let i in props.names){
                this.names.push(props.names[i]);
            }
            this.idate = props.idate;
            this.rdate = props.rdate;
            this.edate = props.edate;
            this.cdate = props.cdate;
            this.ldate = props.ldate;
            this.mix = props.mix;
            this.idtf = [];
            for (let i in props.idtf){
                this.idtf.push(props.idtf[i]);
            }
            this.comp = new Company(props.comp);
            this.subs = new Substance(props.subs);
            this.ci = [];
            for (let i in props.ci) {
                this.ci.push(new TypePhytosanitaryCropInfection(props.ci[i]));
            }
        } else {
            this.id = "-1";
            this.idpdf = "";
            this.code = "";
            this.name = "";
            this.idate = "";
            this.rdate = "";
            this.edate = "";
            this.cdate = "";
            this.ldate = "";
            this.mix = 0;
            this.idtf = [];
            this.comp = null;
            this.subs = null;
            this.ci = [];
        }
    }

    // Devuelve el id del tratamiento.
    getId = () => {
        return this.id;
    }

    // Devuelve el número de registro del tratamiento.
    getCode = () => {
        return this.code;
    }

    // Establece el número de registro del tratamiento.
    setCode = (code) => {
        this.code = code;
    }

    // Devuelve el id del pdf del tratamiento.
    getIDPDF = () => {
        return this.idpdf;
    }

    // Establece el id del pdf del tratamiento.
    setIDPDF = (idpdf) => {
        this.idpdf = idpdf;
    }

    // Devuelve el nombre del tratamiento.
    getName = () => {
        return this.name;
    }

    // Establece el nombre del tratamiento.
    setName = (name) => {
        this.name = name;
    }

    // Devuelve las denominaciones comunes del tratamiento.
    getNames = () => {
        return this.names;
    }

    // Establece las denominaciones comunes del tratamiento.
    setNames = (names) => {
        this.names = names;
    }

    // Añade una denominación común al tratamiento.
    addName = (name) => {
        this.names.push(name);
    }

    // Devuelve  la materia activa del tratamiento.
    getSubs = () => {
        return this.subs;
    }

    // Devuelve el nombre de la materia activa del tratamiento.
    getSubsName = () => {
        return this.subs.getName();
    }

    // Establece la materia activa del tratamiento.
    setSubs = (subs) => {
        this.subs = new Substance(subs);
    }

    // Devuelve la casa comercial del tratamiento.
    getComp = () => {
        return this.comp;
    }

    // Devuelve el nombre de la casa comercial del tratamiento.
    getCompName = () => {
        return this.comp.getName();
    }

    // Establece la casa comercial del tratamiento.
    setComp = (comp) => {
        this.comp = new Company(comp);
    }

    // Devuelve si el tratamiento es mezclable o no.
    getMixable = () => {
        return this.mix;
    }

    // Establece si el tratamiento es mezclable.
    setMixable = (mix) => {
        this.mix = mix;
    }

    // Devuelve la fecha de caducidad del tratamiento.
    getEDate = () => {
        return this.edate;
    }

    // Establece la fecha de caducidad del tratamiento.
    setEDate = (edate) => {
        this.edate = edate;
    }

    // Devuelve la fecha de cancelación del tratamiento.
    getCDate = () => {
        return this.cdate;
    }

    // Establece la fecha de cancelación del tratamiento.
    setCDate = (cdate) => {
        this.cdate = cdate;
    }

    // Devuelve la fecha límite del tratamiento.
    getLDate = () => {
        return this.ldate;
    }

    // Establece la fecha límite del tratamiento.
    setLDate = (ldate) => {
        this.ldate = ldate;
    }

    // Devuelve los CI del tratamiento.
    getCI = () => {
        return this.ci;
    }

    // Establece los CI del tratamiento.
    setCI = (auxCI) => {
        this.ci = [];
        for (let i in auxCI) {
            this.ci.push(new TypePhytosanitaryCropInfection(auxCI[i]));
        }
    }
}

export default TypePhytosanitary;