// Clase del objeto Organization.

import KTP from "./KTP";
import TypeOrganization from "./TypeOrganization";
import Strings from "../strings/Strings";

class Organization {
    // Constructor del objeto Organization.
    constructor(props) {
        this.type = "Organization";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
            this.subtype = new TypeOrganization(props.subtype);
            this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
            this.alias = (props.alias !== "" && props.alias !== undefined ? props.alias : "");
            this.color1 = (props.color1 !== "" && props.color1 !== undefined ? props.color1 : Strings.defaultColor1);
            this.color2 = (props.color2 !== "" && props.color2 !== undefined ? props.color2 : Strings.defaultColor2);
            this.color3 = (props.color3 !== "" && props.color3 !== undefined ? props.color3 : Strings.defaultColor3);
            this.nl = (props.nl !== "" && props.nl !== undefined ? props.nl : 0);
            this.nm = (props.nm !== "" && props.nm !== undefined ? props.nm : 0);
            this.ne = (props.ne !== "" && props.ne !== undefined ? props.ne : 0);
            this.nf = (props.nf !== "" && props.nf !== undefined ? props.nf : 1);
            this.nel = (props.nel !== "" && props.nel !== undefined ? props.nel : 0);
            this.nem = (props.nem !== "" && props.nem !== undefined ? props.nem : 0);
            this.nee = (props.nee !== "" && props.nee !== undefined ? props.nee : 0);
            this.nes = (props.nes !== "" && props.nes !== undefined ? props.nes : 0);
            this.nef = (props.nef !== "" && props.nef !== undefined ? props.nef : 0);
            this.premium = [];
            for (let i in props.premium){
                this.premium.push(new KTP(props.premium[i]));
            }
            this.image = (props.image !== "" && props.image !== undefined ? props.image : "");
        } else {
            this.id = "-1";
            this.subtype = new TypeOrganization();
            this.name = "";
            this.alias = "";
            this.color1 = Strings.defaultColor1;
            this.color2 = Strings.defaultColor2;
            this.color3 = Strings.defaultColor3;
            this.nl = 0;
            this.nm = 0;
            this.ne = 0;
            this.nf = 1;
            this.nel = 0;
            this.nem = 0;
            this.nee = 0;
            this.nes = 0;
            this.nef = 0;
            this.premium = [];
            this.image = "";
        }
    }

    // Obtiene el id de la organización.
    getId = () => {
        return this.id;
    }

    // Obtiene el nombre de la organización.
    getName = () => {
        return this.name;
    }

    // Obtiene el subtipo de la organización.
    getSubtype = () => {
        return this.subtype;
    }

    // Obtiene el alias de la organización.
    getAlias = () => {
        return this.alias;
    }

    // Obtiene el número de administradores de la organización.
    getNl = () => {
        return this.nl;
    }

    // Obtiene el número de técnicos de la organización.
    getNm = () => {
        return this.nm;
    }

    // Obtiene el número de trabajadores externos de la organización.
    getNe = () => {
        return this.ne;
    }

    // Obtiene el número de explotaciones de la organización.
    getNf = () => {
        return this.nf;
    }

    // Obtiene el número de administradores activos de la organización.
    getNel = () => {
        return this.nel;
    }

    // Obtiene el número de técnicos activos de la organización.
    getNem = () => {
        return this.nem;
    }

    // Obtiene el número de trabajadores externos activos de la organización.
    getNee = () => {
        return this.nee;
    }

    // Obtiene el número de agricultores activos de la organización.
    getNes = () => {
        return this.nes;
    }

    // Obtiene el número de explotaciones activas de la organización.
    getNef = () => {
        return this.nef;
    }

    // Obtiene el color de organización 1.
    getColor1 = () => {
        return this.color1;
    }

    // Obtiene el color de organización 2.
    getColor2 = () => {
        return this.color2;
    }

    // Obtiene el color de organización 3.
    getColor3 = () => {
        return this.color3;
    }

    // Obtiene todos los premium de la organización.
    getPremium = () => {
        return this.premium;
    }

    // Obtiene la fecha fin del premium offline de la organización.
    getPremiumOfflineEndDate = () => {
        for (let i in this.premium){
            if (this.premium[i].getIdtp() === KTP.ID_OFFLINE_PREMIUM) {
                return this.premium[i].getEndDate();
            }
        }
        return "";
    }

    // Obtiene la fecha fin del premium de fitosanitarios de la organización.
    getPremiumPhytoEndDate = () => {
        for (let i in this.premium){
            if (this.premium[i].getIdtp() === KTP.ID_PHYTOSANITARY_PREMIUM) {
                return this.premium[i].getEndDate();
            }
        }
        return "";
    }

    // Obtiene la fecha fin del premium de análisis de la organización.
    getPremiumAnalysisEndDate = () => {
        for (let i in this.premium){
            if (this.premium[i].getIdtp() === KTP.ID_ANALYSIS_PREMIUM) {
                return this.premium[i].getEndDate();
            }
        }
        return "";
    }

    // Obtiene la fecha fin del premium de stock de la organización.
    getPremiumStockEndDate = () => {
        for (let i in this.premium){
            if (this.premium[i].getIdtp() === KTP.ID_STOCK_PREMIUM) {
                return this.premium[i].getEndDate();
            }
        }
        return "";
    }

    // Obtiene la fecha fin del premium de gaia de la organización.
    getPremiumGaiaEndDate = () => {
        for (let i in this.premium){
            if (this.premium[i].getIdtp() === KTP.ID_GAIA_PREMIUM) {
                return this.premium[i].getEndDate();
            }
        }
        return "";
    }

    // Obtiene la fecha fin del premium de gaia de la organización.
    getPremiumNirsEndDate = () => {
        for (let i in this.premium){
            if (this.premium[i].getIdtp() === KTP.ID_NIRS_PREMIUM) {
                return this.premium[i].getEndDate();
            }
        }
        return "";
    }
}

export default Organization;