import React, {Component} from 'react';
import PageTitle from "../../component/PageTitle";
import OutMessage from "../../../logic/objects/OutMessage";
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import {
    createAreeiroMessage, createOsigrisMessage,
    deleteMessage,
    getOutMessageList
} from "../../../logic/functions/ServerPetitions";
import Collapsible from "../../component/Collapsible";
import InfiniteScroll from "react-infinite-scroller";
import {Spinner} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import CustomButton from "../../component/CustomButton";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Input from "../../component/Input";
import TextArea from "../../component/TextArea";
import Flatpickr from "react-flatpickr";
// noinspection NpmUsedModulesInstalled
import { Spanish } from 'flatpickr/dist/l10n/es.js';

class MessageInfo extends Component {
    // Constructor de clase MessageInfo.
    constructor(props) {
        super(props);
        this.messages = [];
        this.content = '';
        this.state = {petitionEnded: false, page_size: 10, page: 0, maxReached: false,
            showMessageModal: false, showEditModal: false, editDate: ""};
        this.editingMessage = undefined;
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.messageTitle;
        this.setState({petitionEnded: false});
        getOutMessageList(this.state.page_size, this.state.page).then(async messageList => {
            const messageJson = await messageList.json();
            let reached = parseInt(messageList.headers.get("content-range").split(" ")[1].split("-")[1]) >= parseInt(messageList.headers.get("content-range").split(" ")[2]);
            this.setState({maxReached: reached});

            if (!messageList.ok){
                const error = messageList.status;
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (messageJson.hasOwnProperty('data')){
                for(let i in messageJson.data) {
                    this.messages.push(new OutMessage(messageJson.data[i]));
                }
                this.setState({petitionEnded: true});
                endPreLoad();
            } else {
                refreshIfNeeded(messageJson, null, this.props.showInfoModal);
            }
        }).catch( error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.setState({petitionEnded: true});
                endPreLoad();
            }
        );
    }

    // Dibuja el contenido de la página MessageInfo.
    render = () => {
        if (this.state.petitionEnded) {
            this.content = this.getOutMessageContent();
        }
        let loader = <div className={"row justify-content-center"} key={"loaderdiv"}><Spinner key={"loader"} animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div>;

        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.messageInfoPageTitle}/>

                <div className="card-container">
                    <InfiniteScroll
                        pageStart={this.state.page}
                        loadMore={this.getNextMessagePage}
                        hasMore={!this.state.maxReached}
                        loader={loader}
                        threshold={200}>
                        {this.content}
                    </InfiniteScroll>
                </div>

                {/* Modal Message Confirmation */}
                <Modal show={this.state.showMessageModal} backdrop="static" onHide={this.hideConfirmationModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-confirmation-title" />
                    </ModalHeader>
                    <ModalBody>
                        <div id="modal-confirmation-content" />
                        <div className="row justify-content-center">
                            <div className="col-auto align-self-center">
                                <div id="modal-confirmation-operation" className="font-weight-bold" />
                            </div>
                            <div className="col-auto align-self-center">
                                <input id="modal-confirmation-result" type="number" className="form-control"
                                       placeholder={this.props.strings.operationConfirmation} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton id={"modal-confirmation-button-cancel"} additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.resetConfirmationModal}/>
                        <CustomButton id={"modal-confirmation-button-ok"} additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideConfirmationModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Message Confirmation */}

                {/* Modal Message Edit */}
                <Modal show={this.state.showEditModal} backdrop="static" onHide={this.resetEditModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle>{this.props.strings.messageEditTitle}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <form>
                            <FormGroup>
                                <FormLabel>{this.props.strings.messageSubjectTitle}</FormLabel>
                                <Input id={"editMessageSubject"} type={"text"} ph={this.props.strings.messageSubjectPh} />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>{this.props.strings.messageBodyTitle}</FormLabel>
                                <TextArea id={"editMessageBody"} rows={"5"} ph={this.props.strings.messageBodyPh} />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>{this.props.strings.messageDateTitle}</FormLabel>
                                <div className={"row m-0"}>
                                    <Flatpickr id="editMessageDate" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                               value={this.state.editDate} options={{dateFormat: "d-m-Y H:i", enableTime:true, locale: Spanish, position: "above"}}
                                               onChange={date => {
                                                   this.setState({editDate: date});
                                               }} />
                                    <div className={"col-1 p-0"}>
                                        <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={(e) => this.clearEditDate(e)}>
                                            <i className="fas fa-trash"/>
                                        </CustomButton>
                                    </div>
                                </div>
                            </FormGroup>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.resetEditModal}/>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.confirmEditMessage}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Message Edit */}
            </div>
        );
    }

    // Devuelve un array con los elementos html de cada mensaje.
    getOutMessageContent = () => {
        let content = [];
        let borderClass;
        if (this.state.petitionEnded){
            for (let i in this.messages){
                switch (parseInt(this.messages[i].status.id)){
                    case 1:
                        borderClass = "border-left-info";
                        break;
                    case 2:
                        borderClass = "border-left-success";
                        break;
                    case 3:
                        borderClass = "border-left-danger";
                        break;
                    default:
                        break;
                }
                content.push(<div className={"row"} key={"row" + this.messages[i].info.id}>
                                <div className={"col-12"} key={"col" + this.messages[i].info.id}>
                                    <Collapsible leftColour={borderClass}
                                                 triggerId={"collapseMessageCardTrigger" + this.messages[i].info.id}
                                                 collapseId={"collapseMessageCard" + this.messages[i].info.id}
                                                 cardTitle={this.messages[i].info.subject}>
                                         <div className={"row"} key={"bodyrow" + this.messages[i].info.id}>
                                             <div className={"col-lg-8 col-md-12"} key={"bodyleftcol" + this.messages[i].info.id}>
                                                {this.messages[i].info.body}
                                             </div>
                                             <div className={"col-lg-4 col-md-12 d-flex flex-column-reverse"} key={"bodyrightcol" + this.messages[i].info.id}>
                                                <div className={"d-flex justify-content-end"} key={"buttondiv" + this.messages[i].info.id}>
                                                    <i className={"fas fa-fw fa-pencil-alt text-primary faicon_message"} onClick={() => this.openEditMessage(this.messages[i].info.id)} />
                                                    <i className={"fas fa-fw fa-trash-alt text-primary faicon_message mx-2"} onClick={() => this.actionMessageConfirmation(this.props.strings.deleteModalMessageTitle, this.props.strings.deleteModalMessageBody, () => this.removeMessage(this.messages[i].info.id))} />
                                                </div>
                                             </div>
                                         </div>
                                    </Collapsible>
                                </div>
                            </div>);
            }
        }
        return content;
    }

    // Obtiene la siguiente página de mensajes.
    getNextMessagePage = () => {
        if (this.state.petitionEnded === true) {
            this.setState({page: this.state.page + 1, petitionEnded: false});
            getOutMessageList(this.state.page_size, this.state.page).then(async messageList => {
                const messageJson = await messageList.json();

                let reached = parseInt(messageList.headers.get("content-range").split(" ")[1].split("-")[1]) >= parseInt(messageList.headers.get("content-range").split(" ")[2]);
                this.setState({maxReached: reached});

                if (!messageList.ok) {
                    const error = messageList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (messageJson.hasOwnProperty('data')) {
                    for (let i in messageJson.data) {
                        this.messages.push(new OutMessage(messageJson.data[i]));
                    }
                    this.setState({petitionEnded: true});
                } else {
                    refreshIfNeeded(messageJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                    this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                    console.log(error);
                    this.setState({petitionEnded: true});
                    this.props.hideLoaderModal();
                }
            );
        }
    }

    // Guarda el mensaje a editar, abre el modal de edición de mensajes y lo rellena.
    openEditMessage = (messageId) => {
        for (let i in this.messages){
            if (this.messages[i].info.id === messageId){
                this.editingMessage = new OutMessage(this.messages[i]);
                break;
            }
        }

        if (this.editingMessage !== undefined){
            this.showEditModal();
        }
    }

    // Confirma la edición del mensaje con los datos introducidos en el modal.
    confirmEditMessage = () => {
        let subject = document.getElementById("editMessageSubject").value;
        let body = document.getElementById("editMessageBody").value;
        let date = document.getElementById("editMessageDate").value;

        if (subject !== "" && body !== "" && date !== ""){
            this.resetEditModal();
            this.props.showLoaderModal(this.props.strings.editingMessage);
            date += ":00";
            this.editingMessage.info.subject = subject;
            this.editingMessage.info.body = body;
            this.editingMessage.info.date = date;

            let json =  {};
            json.data = this.editingMessage;

            let areeiro = (this.editingMessage.info.subtype.id !== "2");

            this.editMessage(json, areeiro).then(async messageAsync => {
                const messageJson = await messageAsync.json();

                if (!messageAsync.ok) {
                    const error = messageAsync.status;
                    return Promise.reject(error);
                }

                if (messageJson.hasOwnProperty('data')) {
                    window.location.reload();
                } else {
                    refreshIfNeeded(messageJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
                this.props.hideLoaderModal();
            }).catch(error => {
                this.props.hideLoaderModal();
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
            });
        }
    }

    // Realiza la petición de edición del mensaje pasado dependiendo de si es areeiro u osigris.
    editMessage = (json, areeiro) => {
        if (areeiro){
            return createAreeiroMessage(json);
        } else {
            return createOsigrisMessage(json);
        }
    }

    // Muestra el modal de de editar o borrar mensaje.
    showEditModal = () => {
        this.setState({showEditModal: true});
        setTimeout(function(){
            document.getElementById("editMessageSubject").value = this.editingMessage.info.subject;
            document.getElementById("editMessageBody").value = this.editingMessage.info.body;
            let date = this.editingMessage.info.date.split(":")[0] + ":" + this.editingMessage.info.date.split(":")[1];
            this.setState({editDate: date});
            document.getElementById("editMessageDate").value = date;
        }.bind(this), 50);
    }

    // Cierra el modal de editar o borrar mensaje.
    hideEditModal = () => {
        this.setState({showEditModal: false});
    }

    // Resetea el modal de editar mensaje.
    resetEditModal = () => {
        document.getElementById("editMessageSubject").value = "";
        document.getElementById("editMessageBody").value = "";
        document.getElementById("editMessageDate").value = "";
        this.hideEditModal();
    }

    // Limpia la fecha de edicción de mensaje.
    clearEditDate = (e) => {
        e.preventDefault();
        this.setState({editDate: ""});
    }

    // Abre el modal de confirmación de envío de mensaje con los argumentos pasados como título, cuerpo y función de retorno.
    actionMessageConfirmation = (title, content, callback) => {
        this.setState({showMessageModal: true});
        //Se obtienen los dos numeros para la operacion de confirmación.
        let num_a = Math.floor(Math.random() * 100);
        let num_b = Math.floor(Math.random() * 100);
        //Se muestra la informacion en el modal.
        setTimeout(() => {
            document.getElementById("modal-confirmation-title").innerHTML = title;
            document.getElementById("modal-confirmation-content").innerHTML = content + " " + this.props.strings.confirmationModalOperation;
            document.getElementById("modal-confirmation-operation").innerHTML = num_a + " + " + num_b + " = ";
            //Se establece el evento onclick del boton de confirmacion.
            document.getElementById("modal-confirmation-button-ok").onclick = () => {this.confirmActionMessage(num_a, num_b, callback)};
            document.getElementById("modal-confirmation-button-cancel").onclick = () => {this.resetConfirmationModal()};
        }, 100);
    }

    // Funcionalidad del botón ok para enviar mensaje.
    confirmActionMessage = (num_a, num_b, callback) => {
        let result = parseInt(document.getElementById("modal-confirmation-result").value);
        //Se resetea el input y el evento onclick.
        this.resetConfirmationModal();
        //Se comprueba el resultado y se redirige a la operacion o se muestra un mensaje de error.
        if (num_a + num_b === result){
            this.props.showLoaderModal(this.props.strings.removingMessage);
            callback();
        } else {
            this.props.showInfoModal(this.props.strings.confirmationModalTitleError, this.props.strings.confirmationModalBodyError);
        }
    }

    // Resetea el modal de confirmación de editar o borrar mensaje.
    resetConfirmationModal = () => {
        document.getElementById("modal-confirmation-result").value = "";
        document.getElementById("modal-confirmation-button-ok").removeAttribute("onclick");
        document.getElementById("modal-confirmation-button-cancel").removeAttribute("onclick");
        this.hideConfirmationModal();
    }

    // Cierra el modal de confirmación de editar o borrar mensaje.
    hideConfirmationModal = () => {
        this.setState({showMessageModal: false});
    }

    // Envía la petición de borrado del mensaje seleccionado.
    removeMessage = (messageId) => {
        let message;
        for (let i in this.messages){
            if (this.messages[i].info.id === messageId){
                message = this.messages[i];
                break;
            }
        }

        if (message !== undefined){
            let json = {};
            json.data = message;
            let areeiro = (message.info.subtype.id !== "2");
            deleteMessage(json, areeiro).then(async messageAsync => {
                const messageJson = await messageAsync.json();

                if (!messageAsync.ok) {
                    const error = messageAsync.status;
                    return Promise.reject(error);
                }

                if (messageJson.hasOwnProperty('data')) {
                    window.location.reload();
                } else {
                    refreshIfNeeded(messageJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
                this.props.hideLoaderModal();
            }).catch(error => {
                this.props.hideLoaderModal();
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
            });
        }
    }
}

export default MessageInfo;