import React, {Component} from 'react';

// Crea un componente label.
class PageTitle extends Component {
    // Constructor de label.
    constructor(props) {
        super(props);
        this.id = props.id;
        this.text = props.text;
    }

    // Dibuja la label.
    render() {
        return (
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{this.text}</h1>
            </div>
        );
    }
}

export default PageTitle;