// Clase del objeto NotificationStatus.
class NotificationStatus {
    // Constructor del objeto NotificationStatus.
    constructor(props) {
        if (props !== undefined && props !== null) {
            this.type = "NotificationStatus";
            this.id = (props.id !== "" ? props.id : "1");
            this.name = (props.name !== "" ? props.name : "");
        } else {
            this.type = "NotificationStatus";
            this.id = "1";
            this.name = "Pendiente";
        }
    }
}

export default NotificationStatus;