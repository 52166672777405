import React, {Component} from 'react';
import LoginForm from "./LoginForm";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import CustomButton from "../../component/CustomButton";

import '../../../../css/sb-admin-2.css';
import '../../../../css/login.css';
import Strings from "../../../logic/strings/Strings";

// Clase que contiene el contenido de la página Login.
class Login extends Component {
    // Constructor de clase Login.
    // noinspection DuplicatedCode
    constructor(props) {
        super(props);
        this.strings = new Strings();
        this.state = {showModal: false};
    }

    // Función que se realiza cuando se ha cargado el DOM, establece el color de fondo.
    componentDidMount = () => {
        document.body.classList.add("bg-gradient-primary");
        document.getElementById("root").classList.add("container");
    }

    // Función que se realiza cuando el elemento va a desaparecer, quita el color de fondo.
    componentWillUnmount = () => {
        document.body.classList.remove("bg-gradient-primary");
        document.getElementById("root").classList.remove("container");
    }

    // Dibuja los elementos de la página.
    render = () => {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                {/* Nested Row within Card Body */}
                                <div className="row">
                                    <div className="col-6 d-none d-lg-block bg-login-image" />
                                    <div className="col-12 col-lg-6 form-loader-container">
                                        <div id="loginFormLoader" className="form-loader d-none"/>
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">{this.strings.loginWelcome}</h1>
                                                <LoginForm strings={this.strings} showInfoModal={this.showInfoModal} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModal} backdrop="static" onHide={this.hideInfoModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-info-title"/>
                    </ModalHeader>
                    <ModalBody id="modal-info-body"/>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-primary"} text={this.strings.modalConfirm} parentFunction={this.hideInfoModal}/>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    // Muestra el modal de información con los errores del login.
    showInfoModal = (title, body) => {
        this.setState({showModal: true}, () => {
            document.getElementById("modal-info-title").innerHTML = title;
            document.getElementById("modal-info-body").innerHTML = body;
        });
    }

    // Oculta el modal de información.
    hideInfoModal = () => {
        this.setState({showModal: false});
    }
}

export default Login;