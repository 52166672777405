import TypeInfection from "./TypeInfection";

class Infection {
    constructor(props){
        this.type = "Infection";
        if (props !== undefined && props !== null){
            this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
            this.subtype = new TypeInfection(props.subtype);
            this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
            this.name2 = (props.name2 !== "" && props.name2 !== undefined ? props.name2 : "");
            this.act = (props.act !== "" && props.act !== undefined ? props.act : "");
            this.sign = (props.sign !== "" && props.sign !== undefined ? props.sign : "");
            this.idtypecrop = [];
            for (let i in props.idtypecrop){
                this.idtypecrop.push(props.idtypecrop[i]);
            }
        } else {
            this.id = "-1";
            this.subtype = new TypeInfection();
            this.name = "";
            this.name2 = "";
            this.act = "";
            this.sign = "";
            this.idtypecrop = [];
        }
    }

    // Devuelve el id de la infección.
    getId = () => {
        return this.id;
    }

    // Devuelve el nombre de la infección.
    getName = () => {
        return this.name;
    }
}

export default Infection;