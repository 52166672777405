import React, {Component} from 'react';

// Crea un componente button.
class CustomButton extends Component {
    // Constructor de button.
    constructor(props) {
        super(props);
        this.id = props.id;
        this.type = props.type;
        this.className = "btn " + (props.additionalClass !== undefined ? props.additionalClass : "");
        this.text = props.text;
    }

    // Dibuja el button.
    render() {
        return (
            <button id={this.id} type={this.type} className={this.className} onClick={this.props.parentFunction} disabled={this.props.disabled === true}>{this.text}{(this.props.children ? this.props.children : '')}</button>
        );
    }
}

export default CustomButton;