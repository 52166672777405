import User from "./User";
import SeasonStaff from "./SeasonStaff";

// Clase del objeto UserStaff.
class UserStaff {
    // Constructor del objeto UserStaff.
    constructor(props) {
        this.type = "UserStaff";
        this.user = new User(props.user);
        this.season = [];
        for (let i in props.season){
            this.season.push(new SeasonStaff(props.season[i]));
        }
    }

    // Devuelve el id del usuario.
    getUserId = () => {
        return this.user.getId();
    }

    // Devuelve el loginname del usuario.
    getUserLoginname = () => {
        return this.user.getLoginname();
    }

    // Devuelve el email del usuario.
    getUserEmail = () => {
        return this.user.getEmail();
    }

    // Devuelve el nombre del usuario.
    getUserName = () => {
        return this.user.getName();
    }

    // Devuelve el apellido del usuario.
    getUserSurname = () => {
        return this.user.getSurname();
    }

    // Devuelve el nif del usuario.
    getUserNif = () => {
        return this.user.getNif();
    }
}

export default UserStaff;