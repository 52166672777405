// Clase del objeto Company.
class Company {
    // Constructor del objeto Company.
    constructor(props) {
        this.type = "Company";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== "" && props.id !== undefined && props.id !== null ? props.id : "-1");
            this.name = props.name;
            this.iduser = (props.iduser !== "" ? props.iduser : null);
        } else {
            this.id = "-1";
            this.name = "";
            this.iduser = null;
        }
    }

    // Obtiene el id de la casa comercial.
    getId = () => {
        return this.id;
    }

    // Obtiene el nombre de la casa comercial.
    getName = () => {
        return this.name;
    }

    // Obtiene el id del usuario de la casa comercial.
    getIdUser = () => {
        return (this.iduser !== null ? this.iduser : "");
    }
}

export default Company;