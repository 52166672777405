import {datetimeToString} from "../functions/Basic";

// Clase del objeto CropSigpac.
class CropSigpac {
    // Constructor del objeto CropSigpac.
    constructor(props) {
        this.type = "CropSigpac";
        this.id = (props.id !== "" ? props.id : "-1");
        this.code = (props.code !== "" ? props.code : "");
        this.inidate = (props.inidate !== "" ? props.inidate : datetimeToString(new Date()));
        this.enddate = props.enddate;
        this.idseason = (props.idseason !== "" ? props.idseason : "-1");
        this.idparcel = (props.idparcel !== "" ? props.idparcel : "-1");
        this.idsigpac = (props.idsigpac !== "" ? props.idsigpac : "-1");
        this.idcrop = (props.idcrop !== "" ? props.idcrop : "-1");
    }
}

export default CropSigpac;