import React, {Component} from 'react';

// Crea un componente input.
class Input extends Component {
    // Constructor de input.
    constructor(props) {
        super(props);
        this.id = props.id;
        this.type = props.type;
        this.ph = props.ph;
        this.described = props.described;
        this.className = "form-control " + (props.additionalClass !== undefined ? props.additionalClass : "");
    }

    // Dibuja el input.
    render() {
        return (
            <input id={this.id} value={this.props.value} type={this.type} className={this.className} name={this.id}
                   placeholder={this.ph} aria-describedby={this.described} onChange={this.props.onChange}
                   onInput={this.props.onInput} min={this.props.min} pattern={this.props.pattern} disabled={this.props.disabled}/>
        );
    }
}

export default Input;