// Clase del objeto NotificationReceiver.
class NotificationReceiver {
    // Constructor del objeto NotificationReceiver.
    constructor(props) {
        this.type = "NotificationStatus";
        this.id = (props.id !== "" ? props.id : "1");
        this.name = props.name;
    }
}

export default NotificationReceiver;