// Clase del objeto TypeCrop.
class TypeCrop {
    // Constructor del objeto TypeCrop.
    constructor(props) {
        this.type = "TypeCrop";
        this.id = (props.id !== "" ? props.id : "-1");
        this.code = props.code;
        this.name = props.name;
        this.shepherd = (props.shepherd !== "" ? props.shepherd : "0");
        this.style = props.style;
    }

    // Devuelve el id del tipo de cultivo.
    getId = () => {
        return this.id;
    }

    // Devuelve el nombre del tipo de cultivo.
    getName = () => {
        return this.name;
    }

    // Devuelve el código del tipo de cultivo.
    getCode = () => {
        return this.code;
    }
}

export default TypeCrop;