import React, {Component} from 'react';
import CustomButton from "./CustomButton";
import Dropdown from "react-bootstrap/Dropdown";
import NavLink from "react-bootstrap/NavLink";

// Crea un componente header.
class Header extends Component {
    // Constructor del header.
    constructor(props) {
        super(props);
        this.changeDisplaySidebar = this.changeDisplaySidebar.bind(this);
    }

    // Dibuja el header.
    render() {
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                {/* Sidebar Toggle (Topbar) */}
                <CustomButton id={"sidebarToggleTop"} additionalClass={"btn-link d-md-none rounded-circle mr-3"} parentFunction={this.changeDisplaySidebar}>
                    <i className="fa fa-bars"/>
                </CustomButton>
                {/* Topbar Navbar */}
                <ul className="navbar-nav ml-auto">
                    {/* Nav Item - User Information */}
                    <Dropdown className="nav-item no-arrow" drop={'down'}>
                        <Dropdown.Toggle to="#" as={NavLink}>
                            <span id="sysadmin_loginname" className="mr-2 d-none d-lg-inline text-gray-600 small"/>
                            <img id="sysadmin_image" alt="" className="img-profile rounded-circle"/>
                        </Dropdown.Toggle>
                        {/* Dropdown - User Information */}
                        <Dropdown.Menu className="shadow dropdown-menu-right" >
                            <Dropdown.Item className="dropdown-item" onClick={this.props.logoutFunction}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"/>
                                Cerrar sesión
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ul>
            </nav>
        );
    }

    changeDisplaySidebar(){
        let sidebar = document.getElementById("accordionSidebar")
        if (sidebar.classList.contains("disp-md-none")){
            sidebar.classList.remove("disp-md-none");
        } else {
            sidebar.classList.add("disp-md-none");
        }
    }
}

export default Header;