import {datetimeToString} from "../functions/Basic";
import NotificationStatus from "./NotificationStatus";
import User from "./User";
import Role from "./Role";

// Clase del objeto Control.
class Control {
    // Constructor del objeto Control.
    constructor(props){
        this.type = "Control";
        this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
        this.date = (props.date !== "" && props.date !== undefined ? props.date : datetimeToString(new Date()));
        this.offline = (props.offline !== "" && props.offline !== undefined ? props.offline : "0");
        this.notified = new NotificationStatus(props.notified);
        this.user = new User(props.user);
        this.role = new Role(props.role);
        this.idseason = (props.idseason !== "" && props.idseason !== undefined ? props.idseason : "");
    }

    // Devuelve el usuario.
    getUser = () => {
        return this.user;
    }

    // Devuelve el rol.
    getRole = () => {
        return this.role;
    }

    // Establece el rol.
    setRole = (role) => {
        this.role = role;
    }
}

export default Control;