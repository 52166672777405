// Clase del objeto TypeKey.

class TypeKey {
    // Constructor del objeto TypeKey.
    constructor(props) {
        this.type = "TypeKey";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== "" && props.id !== undefined ? props.id : -1);
            this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
        } else {
            this.id = -1;
            this.name = "";
        }
    }

    static ID_SOPORTE = 1;
    static ID_DEMO = 2;
    static ID_ORG = 3;
    static ID_MANAGER = 4;
    static ID_EXTERNO = 5;
    static ID_INDIVIDUAL = 6;

    // Obtiene el id del tipo de key.
    getId = () => {
        return this.id;
    }

    // Obtiene el nombre del tipo de key.
    getName = () => {
        return this.name;
    }

    // Devuelve si el tipo es de soporte.
    isSupport = () => {
        return this.id === TypeKey.ID_SOPORTE;
    }
}

export default TypeKey;