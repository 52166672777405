import TypeMeasure from "./TypeMeasure";

class Measure {
    constructor(props){
        this.type = "Measure";
        if (props !== undefined && props !== null){
            this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
            this.subtype = new TypeMeasure(props.subtype);
            this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
            this.symbol = (props.symbol !== "" && props.symbol !== undefined ? props.symbol : "");
            this.prior = (props.prior !== "" && props.prior !== undefined ? props.prior : "");
            this.detail = (props.detail !== "" && props.detail !== undefined ? props.detail : "");
        } else {
            this.id = "-1";
            this.subtype = new TypeMeasure();
            this.name = "";
            this.symbol = "";
            this.prior = "";
            this.detail = "";
        }
    }

    // Devuelve el id.
    getId = () => {
        return this.id;
    }

    // Devuelve el subtipo de medida.
    getSubtype = () => {
        return this.subtype;
    }

    // Devuelve el símbolo de la unidad de medida.
    getSymbol = () => {
        return this.symbol;
    }
}

export default Measure;