import React, {Component} from 'react';

// Crea un componente card de dashboard.
class Card extends Component {
    // Constructor de la clase card de dashboard.
    constructor(props) {
        super(props);
        this.cardColorClass = "card shadow h-100 py-2 " + (props.cardColorClass !== undefined ? props.cardColorClass : "");
        this.titleColorClass = "text-xs font-weight-bold text-uppercase mb-1 " + (props.titleColorClass !== undefined ? props.titleColorClass : "");
        this.id = props.id;
        this.containerId = props.containerId;
        this.text = props.text;
        this.iconClass = "fas fa-2x text-gray-300 " + (props.iconClass !== undefined ? props.iconClass : "");
    }

    // Dibuja la card de dashboard.
    render() {
        return (
            <div id={this.containerId} className={this.cardColorClass}>
                <div className="card-body">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className={this.titleColorClass}>{this.text}</div>
                            <div id={this.id} className="h5 mb-0 font-weight-bold text-gray-800"/>
                        </div>
                        <div className="col-auto">
                            <i className={this.iconClass}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Card;