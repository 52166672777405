import React, {Component} from 'react';
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import PageTitle from "../../component/PageTitle";
import Collapsible from "../../component/Collapsible";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Input from "../../component/Input";
import TextArea from "../../component/TextArea";
import Flatpickr from "react-flatpickr";
import CustomButton from "../../component/CustomButton";
// noinspection NpmUsedModulesInstalled
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import {
    getMessageTypes,
    createOsigrisMessage,
    createAreeiroMessage,
    getJobAsync, getJobAsyncResult
} from "../../../logic/functions/ServerPetitions";
import TypeMessage from "../../../logic/objects/TypeMessage";
import OutMessage from "../../../logic/objects/OutMessage";
import JobAsync from "../../../logic/objects/JobAsync";

class MessageManagement extends Component {
    // Constructor de clase MessageManagement.
    constructor(props) {
        super(props);
        this.state = {osigrisDate: "", areeiroDate: "", showMessageModal: false, exitPetition: false};
        this.messagetypes = [];
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.messageTitle;
        getMessageTypes().then(async messageTypesAsync => {
            const messageTypesJson = await messageTypesAsync.json();

            if (!messageTypesAsync.ok){
                const error = (messageTypesJson && messageTypesAsync.error) || messageTypesAsync.status;
                return Promise.reject(error);
            }

            if (messageTypesJson.hasOwnProperty('data') && messageTypesJson.data.length > 0){
                this.messagetypes = [];
                for (let i in messageTypesJson.data){
                    this.messagetypes.push(new TypeMessage(messageTypesJson.data[i]));
                }
            } else {
                refreshIfNeeded(messageTypesJson, null, this.props.showInfoModal);
            }
            endPreLoad();
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            endPreLoad();
        });
    }

    // Función que se realiza al abandonar la página. Cancela las peticiones async.
    componentWillUnmount = () => {
        this.setState({exitPetition: true});
    }

    // Dibuja el contenido de la página MessageManagement.
    render = () => {
        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.messageManagementPageTitle}/>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <Collapsible triggerId={"collapseSendOsigrisMessageTrigger"}
                                     collapseId={"collapseSendOsigrisMessageCard"}
                                     cardTitle={this.props.strings.messageOsigrisMessageTitle}>
                            <div id="sendOsigrisMessageFormLoader" className="form-loader d-none"/>
                            <form id="sendOsigrisMessageForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.messageSubjectTitle}</FormLabel>
                                            <Input id={"sendOsigrisMessageSubject"} type={"text"} ph={this.props.strings.messageSubjectPh} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.messageBodyTitle}</FormLabel>
                                            <TextArea id={"sendOsigrisMessageBody"} rows={"5"} ph={this.props.strings.messageBodyPh} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.messageDateTitle}</FormLabel>
                                            <div className={"row m-0"}>
                                                <Flatpickr id="sendOsigrisMessageDate" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                                           value={this.state.osigrisDate} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                           onChange={date => {
                                                               this.setState({osigrisDate: date});
                                                           }} />
                                               <div className={"col-1 p-0"}>
                                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOsigrisDate}>
                                                        <i className="fas fa-trash"/>
                                                    </CustomButton>
                                               </div>
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className={"col-lg-4 col-md-12 d-flex flex-column-reverse"}>
                                        <div className={"d-flex justify-content-end"}>
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.sendMessageConfirmation(this.props.strings.confirmationModalMessageTitle, this.props.strings.confirmationModalMessageBody, this.sendOsigrisMessageForm2Server)}>
                                                <span className="icon">
                                                  <i className="fas fa-envelope"/>
                                                </span>
                                                <span className="text">{this.props.strings.modalSendButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <Collapsible triggerId={"collapseSendAreeiroMessageTrigger"}
                                     collapseId={"collapseSendAreeiroMessageCard"}
                                     cardTitle={this.props.strings.messageAreeiroMessageTitle}>
                            <div id="sendAreeiroMessageFormLoader" className="form-loader d-none"/>
                            <form id="sendAreeiroMessageForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.messageSubjectTitle}</FormLabel>
                                            <Input id={"sendAreeiroMessageSubject"} type={"text"} ph={this.props.strings.messageSubjectPh} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.messageBodyTitle}</FormLabel>
                                            <TextArea id={"sendAreeiroMessageBody"} rows={"5"} ph={this.props.strings.messageBodyPh} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.messageDateTitle}</FormLabel>
                                            <div className={"row m-0"}>
                                                <Flatpickr id="sendAreeiroMessageDate" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                                           value={this.state.areeiroDate} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                           onChange={date => {
                                                               this.setState({areeiroDate: date});
                                                           }} />
                                                <div className={"col-1 p-0"}>
                                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearAreeiroDate}>
                                                        <i className="fas fa-trash"/>
                                                    </CustomButton>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className={"col-lg-4 col-md-12 d-flex flex-column-reverse"}>
                                        <div className={"d-flex justify-content-end"}>
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.sendMessageConfirmation(this.props.strings.confirmationModalMessageTitle, this.props.strings.confirmationModalMessageBody, this.sendAreeiroMessageForm2Server)}>
                                                <span className="icon">
                                                  <i className="fas fa-envelope"/>
                                                </span>
                                                <span className="text">{this.props.strings.modalSendButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                {/* Modal Message Confirmation */}
                <Modal show={this.state.showMessageModal} backdrop="static" onHide={this.hideMessageModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-confirmation-title" />
                    </ModalHeader>
                    <ModalBody>
                        <div id="modal-confirmation-content" />
                        <div className="row justify-content-center">
                            <div className="col-auto align-self-center">
                                <div id="modal-confirmation-operation" className="font-weight-bold" />
                            </div>
                            <div className="col-auto align-self-center">
                                <input id="modal-confirmation-result" type="number" className="form-control"
                                       placeholder={this.props.strings.operationConfirmation} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton id={"modal-confirmation-button-cancel"} additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.resetConfirmationModal}/>
                        <CustomButton id={"modal-confirmation-button-ok"} additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideMessageModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Message Confirmation */}
            </div>
        );
    }

    // Abre el modal de confirmación de envío de mensaje con los argumentos pasados como título, cuerpo y función de retorno.
    sendMessageConfirmation = (title, content, callback) => {
        this.setState({showMessageModal: true});
        //Se obtienen los dos numeros para la operacion de confirmación.
        let num_a = Math.floor(Math.random() * 100);
        let num_b = Math.floor(Math.random() * 100);
        //Se muestra la informacion en el modal.
        setTimeout(() => {
            document.getElementById("modal-confirmation-title").innerHTML = title;
            document.getElementById("modal-confirmation-content").innerHTML = content + " " + this.props.strings.confirmationModalOperation;
            document.getElementById("modal-confirmation-operation").innerHTML = num_a + " + " + num_b + " = ";
            //Se establece el evento onclick del boton de confirmacion.
            document.getElementById("modal-confirmation-button-ok").onclick = () => {this.confirmSendMessage(num_a, num_b, callback)};
            document.getElementById("modal-confirmation-button-cancel").onclick = () => {this.resetConfirmationModal()};
        }, 100);
    }

    // Funcionalidad del botón ok para enviar mensaje.
    confirmSendMessage = (num_a, num_b, callback) => {
        let result = parseInt(document.getElementById("modal-confirmation-result").value);
        //Se resetea el input y el evento onclick.
        this.resetConfirmationModal();
        //Se comprueba el resultado y se redirige a la operacion o se muestra un mensaje de error.
        if (num_a + num_b === result){
            callback();
        } else {
            this.props.showInfoModal(this.props.strings.confirmationModalTitleError, this.props.strings.confirmationModalBodyError);
        }
    }

    // Resetea el modal de confirmación.
    resetConfirmationModal = () => {
        document.getElementById("modal-confirmation-result").value = "";
        document.getElementById("modal-confirmation-button-ok").removeAttribute("onclick");
        document.getElementById("modal-confirmation-button-cancel").removeAttribute("onclick");
        this.hideMessageModal();
    }

    // Cierra el modal de confirmación de envío de mensaje.
    hideMessageModal = () => {
        this.setState({showMessageModal: false});
    }

    // Limpia la fecha de envío de mensaje de oSIGris.
    clearOsigrisDate = () => {
        this.setState({osigrisDate: undefined});
    }

    // Enseña el loader del formulario para enviar mensaje de Osigris.
    showOsigrisLoader = () => {
        document.getElementById("sendOsigrisMessageFormLoader").classList.remove("d-none");
        document.getElementById("sendOsigrisMessageForm").classList.add("d-none");
    }

    // Oculta el loader del formulario para enviar mensajes de Osigris.
    hideOsigrisLoader = () => {
        document.getElementById("sendOsigrisMessageFormLoader").classList.add("d-none");
        document.getElementById("sendOsigrisMessageForm").classList.remove("d-none");
    }

    // Crea un mensaje de Osigris.
    updateOsigrisMessageInfo = () => {
        let messageType;
        for (let i in this.messagetypes){
            if (this.messagetypes[i].id === "2"){
                messageType = this.messagetypes[i];
            }
        }
        let json = {};
        json.data = new OutMessage({info: {
            subtype: new TypeMessage(messageType),
            subject: document.getElementById("sendOsigrisMessageSubject").value,
            body: document.getElementById("sendOsigrisMessageBody").value,
            date: document.getElementById("sendOsigrisMessageDate").value + " 21:00:00"}
        });
        return json;
    }

    // Resetea los campos del formulario para crear mensajes de Osigris.
    sendOsigrisMessageFormReset = () => {
        document.getElementById("sendOsigrisMessageSubject").value = "";
        document.getElementById("sendOsigrisMessageBody").value = "";
        document.getElementById("sendOsigrisMessageDate").value = "";
        this.setState({osigrisDate: ""});
    }

    // Envía el mensaje de oSIGris al servidor.
    sendOsigrisMessageForm2Server = () => {
        let json = this.updateOsigrisMessageInfo();
        // Se comprueba que la información del mensaje es correcta.
        if (json === null) {
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            // Se resetea el formulario.
            this.sendOsigrisMessageFormReset();
        } else {
            this.showOsigrisLoader();
            createOsigrisMessage(json).then(async messageAsync => {
                const messageJson = await messageAsync.json();

                if (!messageAsync.ok) {
                    const error = messageAsync.status;
                    return Promise.reject(error);
                }

                if (messageJson.hasOwnProperty('data')) {
                    let jobAsync = new JobAsync(messageJson.data);
                    // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                    this.getJobAsyncSendOsigrisMessage(jobAsync.id, this.props.globals.getMinTimeout());
                } else {
                    refreshIfNeeded(messageJson, this.hideOsigrisLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
                this.hideOsigrisLoader();
            });
        }
    }

    // Petición asíncrona para enviar mensaje de oSIGris.
    getJobAsyncSendOsigrisMessage = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async osigrisPromise => {
                const osigrisPromiseJson = await osigrisPromise.json();

                if (!osigrisPromise.ok){
                    const error = osigrisPromise.status;
                    return Promise.reject(error);
                }

                if (osigrisPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(osigrisPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data') && jobAnswerJson.data.hasOwnProperty('result') && jobAnswerJson.data['result'] === 'ok'){
                                this.props.showInfoModal(this.props.strings.updateModalMessageOkTitle, this.props.strings.updateModalMessageOkBody);
                                // Se resetea el formulario.
                                this.sendOsigrisMessageFormReset();
                                this.hideOsigrisLoader();
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hideOsigrisLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                            this.sendOsigrisMessageFormReset();
                        });
                        this.sendOsigrisMessageFormReset();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncSendOsigrisMessage(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(osigrisPromiseJson, this.hideOsigrisLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.sendOsigrisMessageFormReset();
            });
        }, timeout);
    }

    // Limpia la fecha de envío de mensaje de Areeiro.
    clearAreeiroDate = () => {
        this.setState({areeiroDate: undefined});
    }

    // Enseña el loader del formulario para enviar mensaje de Areeiro.
    showAreeiroLoader = () => {
        document.getElementById("sendAreeiroMessageFormLoader").classList.remove("d-none");
        document.getElementById("sendAreeiroMessageForm").classList.add("d-none");
    }

    // Oculta el loader del formulario para enviar mensajes de Areeiro.
    hideAreeiroLoader = () => {
        document.getElementById("sendAreeiroMessageFormLoader").classList.add("d-none");
        document.getElementById("sendAreeiroMessageForm").classList.remove("d-none");
    }

    // Crea un mensaje de Areeiro.
    updateAreeiroMessageInfo = () => {
        let messageType;
        for (let i in this.messagetypes){
            if (this.messagetypes[i].id === "3"){
                messageType = this.messagetypes[i];
            }
        }
        let json = {};
        json.data = new OutMessage({info: {
                subtype: new TypeMessage(messageType),
                subject: document.getElementById("sendAreeiroMessageSubject").value,
                body: document.getElementById("sendAreeiroMessageBody").value,
                date: document.getElementById("sendAreeiroMessageDate").value + " 21:00:00"}
        });
        return json;
    }

    // Resetea los campos del formulario para crear mensajes de Areeiro.
    sendAreeiroMessageFormReset = () => {
        document.getElementById("sendAreeiroMessageSubject").value = "";
        document.getElementById("sendAreeiroMessageBody").value = "";
        document.getElementById("sendAreeiroMessageDate").value = "";
        this.setState({areeiroDate: ""});
    }

    // Envía el mensaje de Areeiro al servidor.
    sendAreeiroMessageForm2Server = () => {
        let json = this.updateAreeiroMessageInfo();
        // Se comprueba que la información del mensaje es correcta
        if (json === null) {
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            // Se resetea el formulario.
            this.sendAreeiroMessageFormReset();
        } else {
            this.showAreeiroLoader();
            createAreeiroMessage(json).then(async messageAsync => {
                const messageJson = await messageAsync.json();

                if (!messageAsync.ok) {
                    const error = messageAsync.status;
                    return Promise.reject(error);
                }

                if (messageJson.hasOwnProperty('data')) {
                    let jobAsync = new JobAsync(messageJson.data);
                    // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                    this.getJobAsyncSendAreeiroMessage(jobAsync.id, this.props.globals.getMinTimeout());
                } else {
                    refreshIfNeeded(messageJson, this.hideAreeiroLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
                this.hideAreeiroLoader();
            });
        }
    }

    // Petición asíncrona para enviar mensaje de Areeiro.
    getJobAsyncSendAreeiroMessage = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async areeiroPromise => {
                const areeiroPromiseJson = await areeiroPromise.json();

                if (!areeiroPromise.ok){
                    const error = areeiroPromise.status;
                    return Promise.reject(error);
                }

                if (areeiroPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(areeiroPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data') && jobAnswerJson.data.hasOwnProperty('result') && jobAnswerJson.data['result'] === 'ok'){
                                this.props.showInfoModal(this.props.strings.updateModalMessageOkTitle, this.props.strings.updateModalMessageOkBody);
                                // Se resetea el formulario.
                                this.sendAreeiroMessageFormReset();
                                this.hideAreeiroLoader();
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hideAreeiroLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                            this.sendAreeiroMessageFormReset();
                        });
                        this.sendAreeiroMessageFormReset();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncSendAreeiroMessage(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(areeiroPromiseJson, this.hideAreeiroLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.sendAreeiroMessageFormReset();
            });
        }, timeout);
    }
}

export default MessageManagement;