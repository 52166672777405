import DashboardIncidence from "./DashboardIncidence";

// Clase del objeto DashboardTown.
class DashboardTown {
    // Constructor del objeto DashboardTown.
    constructor(props) {
        this.type = "DbT";
        this.province = props.province;
        this.town = props.town;
        this.farm = props.farm;
        this.incidence = new DashboardIncidence(props.incidence);
    }

    // Obtiene la cantidad de infecciones.
    infoInfectionAmount = () => {
        return this.incidence.infoInfectionAmount();
    };

    // Obtiene la cantidad de fertilizantes.
    infoFertilizeAmount = () => {
        return this.incidence.infoFertilizeAmount();
    };

    // Obtiene la cantidad de fitosanitarios.
    infoPhytosanitaryAmount = () => {
        return this.incidence.infoPhytosanitaryAmount();
    };

    // Obtiene la cantidad de estados fenológicos.
    infoStateAmount = () => {
        return this.incidence.infoStateAmount();
    };

    // Obtiene la cantidad de recolectas.
    infoCollectAmount = () => {
        return this.incidence.infoCollectAmount();
    };

    // Obtiene la cantidad de labores realizadas.
    infoWorkAmount = function () {
        return this.incidence.infoWorkAmount();
    };

    // Obtiene la cantidad de análisis realizados.
    infoAnalysisAmount = function () {
        return this.incidence.infoAnalysisAmount();
    };

    // Obtiene la cantidad de cosechas comercializadas.
    infoHarvestAmount = function () {
        return this.incidence.infoHarvestAmount();
    };

    // Obtiene la cantidad de pastoreos realizados.
    infoShepherdAmount = function () {
        return this.incidence.infoShepherdAmount();
    };

    // Obtiene la cantidad de cultivos plantados.
    infoCropAmount = function () {
        return this.incidence.infoCropAmount();
    };

    // Obtiene la cantidad de notas añadidas.
    infoNoteAmount = function () {
        return this.incidence.infoNoteAmount();
    };
}

export default DashboardTown;