// Clase del objeto TypeProtection.
class TypeProtection {
    // Constructor del objeto TypeProtection.
    constructor(props) {
        this.type = "TypeProtection";
        // noinspection DuplicatedCode
        if (props !== null) {
            this.id = props.id;
            this.name = props.name;
            this.acronym = props.acronym;
            this.detail = props.detail;
        } else {
            this.id = "-1";
            this.name = "";
            this.acronym = "";
            this.detail = "";
        }
    }

    // Devuelve el id del tipo de protección.
    getId = () => {
        return this.id;
    }

    // Devuelve el nombre del tipo de protección.
    getName = () => {
        return this.name;
    }
}

export default TypeProtection;