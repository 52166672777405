import React, {Component} from 'react';

// Crea un componente textarea.
class TextArea extends Component {
    // Constructor de textarea.
    constructor(props) {
        super(props);
        this.id = props.id;
        this.rows = props.rows;
        this.ph = props.ph;
        this.described = props.described;
        this.className = "form-control " + (props.additionalClass !== undefined ? props.additionalClass : "");
    }

    // Dibuja el input.
    render() {
        return (
            <textarea id={this.id} rows={this.rows} className={this.className} name={this.id} placeholder={this.ph} aria-describedby={this.described} onChange={this.props.onChange} />
        );
    }
}

export default TextArea;