import InfoCrop from "./InfoCrop";

// Clase del objeto SeasonStaff.
class SeasonStaff {
    // Constructor del objeto SeasonStaff.
    constructor(props) {
        this.type = "SeasonStaff";
        this.name = props.name;
        this.crop = {}
        for (let i in props.crop){
            let auxCrop = new InfoCrop(props.crop[i]);
            this.crop[auxCrop.getId()] = auxCrop;
        }
    }
}

export default SeasonStaff;