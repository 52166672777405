import DashboardDay from "./DashboardDay";
import DashboardIncidence from "./DashboardIncidence";

// Clase del objeto DashboardData.
class DashboardData {
    // Constructor del objeto DashboardData.
    constructor(props) {
        this.type = "Db";
        this.user = [];
        for(let i in props.user){
            this.user.push(new DashboardDay(props.user[i]));
        }
        this.farm = props.farm;
        this.season = [];
        for(let i in props.season){
            this.season.push(new DashboardDay(props.season[i]));
        }
        this.machine = [];
        for(let i in props.machine){
            this.machine.push(new DashboardDay(props.machine[i]));
        }
        this.local = [];
        for(let i in props.local){
            this.local.push(new DashboardDay(props.local[i]));
        }
        this.message = [];
        for(let i in props.message){
            this.message.push(new DashboardDay(props.message[i]));
        }
        this.notification = [];
        for(let i in props.notification){
            this.notification.push(new DashboardDay(props.notification[i]));
        }
        this.incidence = new DashboardIncidence(props.incidence);
    }

    // Obtiene la cantidad de usuarios.
    infoUserAmount = () => {
        let total = 0;
        for(let i in this.user){
            total += this.user[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de explotaciones.
    infoFarmAmount = () => {
        return this.farm;
    };

    // Obtiene la cantidad de temporadas.
    infoSeasonAmount = () => {
        let total = 0;
        for(let i in this.season){
            total += this.season[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de maquinaria.
    infoMachineAmount = () => {
        let total = 0;
        for(let i in this.machine){
            total += this.machine[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de maquinaria.
    infoLocalAmount = () => {
        let total = 0;
        for(let i in this.local){
            total += this.local[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de mensajes.
    infoMessageAmount = () => {
        let total = 0;
        for(let i in this.message){
            total += this.message[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de notificaciones.
    infoNotificationAmount = () => {
        let total = 0;
        for(let i in this.notification){
            total += this.notification[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de infecciones.
    infoInfectionAmount = () => {
        return this.incidence.infoInfectionAmount();
    };

    // Obtiene la cantidad de fertilizantes.
    infoFertilizeAmount = () => {
        return this.incidence.infoFertilizeAmount();
    };

    // Obtiene la cantidad de fitosanitarios.
    infoPhytosanitaryAmount = () => {
        return this.incidence.infoPhytosanitaryAmount();
    };

    // Obtiene la cantidad de estados fenológicos.
    infoStateAmount = () => {
        return this.incidence.infoStateAmount();
    };

    // Obtiene la cantidad de recolectas.
    infoCollectAmount = () => {
        return this.incidence.infoCollectAmount();
    };

    // Obtiene la cantidad de labores.
    infoWorkAmount = () => {
        return this.incidence.infoWorkAmount();
    };

    // Obtiene la cantidad de riego.
    infoIrrigationAmount = () => {
        return this.incidence.infoIrrigationAmount();
    };

    // Obtiene la cantidad de análisis.
    infoAnalysisAmount = () => {
        return this.incidence.infoAnalysisAmount();
    };

    // Obtiene la cantidad de cosechas comercializadas.
    infoHarvestAmount = () => {
        return this.incidence.infoHarvestAmount();
    };

    // Obtiene la cantidad de pastoreo.
    infoShepherdAmount = () => {
        return this.incidence.infoShepherdAmount();
    };

    // Obtiene la cantidad de cultivos.
    infoCropAmount = () => {
        return this.incidence.infoCropAmount();
    };

    // Obtiene la cantidad de notas.
    infoNoteAmount = () => {
        return this.incidence.infoNoteAmount();
    };
}

export default DashboardData;