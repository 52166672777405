// Clase del objeto TypeLicense.
class TypeLicense {
    // Constructor del objeto TypeLicense.
    constructor(props) {
        this.type = "TypeLicense";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
            this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
            this.detail = props.detail;
        } else {
            this.id = "-1";
            this.name = "";
            this.detail = "";
        }
    }

    // Devuelve el id de licencia.
    getId = () => {
        return this.id;
    }

    // Devuelve el nombre del tipo de licencia.
    getName = () => {
        return this.name.substring(0,1).toUpperCase() +
            this.name.substring(1);
    }
}

export default TypeLicense;