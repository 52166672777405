// Clase del objeto Substance.
class Substance {
    // Constructor del objeto Substance.
    constructor(props) {
        this.type = "Substance";
        this.id = (props.id !== "" ? props.id : "-1");
        this.name = props.name;
    }

    // Devuelve el id.
    getId = () => {
        return this.id;
    }

    // Devuelve el nombre.
    getName = () => {
        return this.name;
    }
}

export default Substance;