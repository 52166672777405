import JobStatus from "./JobStatus";

// Clase del objeto JobAsync.
class JobAsync {
    // Constructor del objeto JobAsync.
    constructor(props) {
        this.type = "JobAsync";
        this.id = props.id;
        this.inidate = props.inidate;
        this.status = new JobStatus(props.status);
        this.percentage = props.percentage;
        this.result = props.result;
        this.iduser = props.iduser;
    }

    // Devuelve si ha finalizado o no.
    hasFinished = () => {
        return this.status.hasFinished();
    }
}

export default JobAsync;